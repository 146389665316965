import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../functions/auth";

export default function CreateAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [bootDate, setBootDate] = useState(null);
  const [mos, setMOS] = useState("");

  const navigate = useNavigate();

  const userData = {
    email,
    branch: "Army",
    rank: "recruit",
    mos,
    bootcampDate: bootDate,
    week: 1,
    day: [
      {
        day: 1,
        completed: false,
      },
      {
        day: 2,
        completed: false,
      },
      {
        day: 3,
        completed: false,
      },
      {
        day: 4,
        completed: false,
      },
      {
        day: 5,
        completed: false,
      },
      {
        day: 6,
        completed: false,
      },
      {
        day: 7,
        completed: false,
      },
    ],
    courseCompletion: [
      {
        coreValues: false,
        title: "Core Values",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },
      {
        fitness: false,
        title: "Physical Training (PT)",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },

      {
        firstaid: false,
        title: "First Aid",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },
      {
        leadership: false,
        title: "Leadership",
        percentageCompleted: 0,
        grades: [],
        phase: 3,
      },
      {
        teamwork: false,
        title: "Teamwork",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },
      {
        landnav: false,
        title: "Land Navigation",
        percentageCompleted: 0,
        grades: [],
        phase: 2,
      },
      {
        weapons: false,
        title: "Weapons",
        percentageCompleted: 0,
        grades: [],
        phase: 3,
      },
      {
        drill: false,
        title: "Drill and Ceremony",
        percentageCompleted: 0,
        grades: [],
        phase: 2,
      },
      {
        customs: false,
        title: "Customs and Courtesies",
        percentageCompleted: 0,
        grades: [],
        phase: 2,
      },
      {
        combatTraining: false,
        title: "Combat Training",
        percentageCompleted: 0,
        grades: [],
        phase: 3,
      },
      {
        les: false,
        title: "Leave and Earnings Statement (LES)",
        percentageCompleted: 0,
        grades: [],
        phase: 4,
      },
      {
        tacticalTraining: false,
        title: "Tactical Training",
        percentageCompleted: 0,
        grades: [],
        phase: 4,
      },
      {
        creed: false,
        title: "Soldier's Creed",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },
      {
        history: false,
        title: "Army History",
        percentageCompleted: 0,
        grades: [],
        phase: 2,
      },
      {
        ethos: false,
        title: "Ethos",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },
      {
        uniform: false,
        title: "Uniform and Appearance",
        percentageCompleted: 0,
        grades: [],
        phase: 2,
      },
      {
        militaryJustice: false,
        title: "Military Justice",
        percentageCompleted: 0,
        grades: [],
        phase: 4,
      },
      {
        generalOrders: false,
        title: "General Orders",
        percentageCompleted: 0,
        grades: [],
        phase: 1,
      },
      {
        fieldTraining: false,
        title: "Field Training",
        percentageCompleted: 0,
        grades: [],
        phase: 3,
      },
      {
        advancedWeapons: false,
        title: "Advanced Weapons",
        percentageCompleted: 0,
        grades: [],
        phase: 4,
      },
    ],
    finishedSetup: false,
    createdAt: new Date(),
    currentWeight: 0,
    currentHeight: 0,
    currentAge: 0,
    currentGender: "",
    goalWeight: 0,
    recordedWeights: [],
    lastRecordedPushups: 0,
    lastRecordedSitups: 0,
    lastRecordedRunTime: 0,
    recordedPushups: [],
    recordedSitups: [],
    recordedRunTime: [],
    greenPhaseCompletion: 0,
    redPhaseCompletion: 0,
    whitePhaseCompletion: 0,
    bluePhaseCompletion: 0,
    rfcrank: 0,
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    } else {
      setIsLoading(true);
      signUp(email, password, userData).then((user) => {
        setTimeout(() => {
          setIsLoading(false);
          navigate("/welcome");
        }, 2000);
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
          <div>Loading</div>
        </div>
      ) : (
        <div className="bg-zinc-950/80 h-screen">
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="mt-4 text-center text-2xl font-bold leading-9 text-white font-frank uppercase tracking-wider ">
                Create an account
              </h2>
            </div>

            <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email address
                  </label>
                  <div>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Password
                    </label>
                  </div>
                  <div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Confirm Password
                    </label>
                  </div>
                  <div>
                    <input
                      id="confirm-password"
                      name="confirm-password"
                      type="password"
                      autoComplete="confirm-password"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
          

                <div>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 h-12"
                    onClick={(e) => handleCreateAccount(e)}
                  >
                    Create Account
                  </button>
                </div>
              </form>

              <p className="mt-10 text-center text-sm text-gray-400 ">
                Already Have an Account?{" "}
                <Link
                  to="/home"
                  className="font-semibold leading-6 text-green-400 hover:text-green-300"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
