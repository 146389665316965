import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../firebase/fire";
import { doc, setDoc } from "firebase/firestore";

export const signIn = async (email, password) => {
  console.log(email, password, "email, password");
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    console.log(res, res);
    return res.user;
  } catch (error) {
    return error;
  }
};

export const logout = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    return error;
  }
};

export const signUp = async (email, password, userData) => {
  try {
    const user = await createUserWithEmailAndPassword(auth, email, password);
    await setDoc(doc(db, "users", user.user.uid), {
      ...userData,
      uid: user.user.uid,
      courses: [],
    });
    return user.user;
  } catch (error) {
    return error;
  }
};

export const forgotPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    return error;
  }
};
