import React from "react";
import MultipleChoice from "./MultipleChoice";
import TrueFalse from "./TrueFalse";
import FillInBlank from "./FillInBlank";
import Matching from "./Matching";
import Order from "./Order";
import Memorize from "./Memorize";
import SelectAllThatApply from "./SelectAllThatApply";

export default function AddQuestion({ questionType, setQuestionData, questionData, display, setDisplay }) {
  const qTypes = [
    <MultipleChoice setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
    <TrueFalse setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
    <FillInBlank setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
    <Matching setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
    <Order setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
    <Memorize setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
    <SelectAllThatApply setQuestionData={setQuestionData} questionData={questionData} display={display} setDisplay={setDisplay}  />,
  ];

  return <div>{qTypes[questionType]}</div>;
}
