import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyDct0Xee99tnus1sKk8-s1apHIXZlwbamA",
  authDomain: "readyfrontcover-26476.firebaseapp.com",
  projectId: "readyfrontcover-26476",
  storageBucket: "readyfrontcover-26476.appspot.com",
  messagingSenderId: "1009911802880",
  appId: "1:1009911802880:web:a8d47f2d1889aa1e10fce5",
  measurementId: "G-VSBYKQGCML",
};

// Initialize the default Firebase app
const app = initializeApp(firebaseConfig);

// Get references to Firestore, Auth, and the existing storage bucket
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
