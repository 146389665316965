import { useEffect, useState } from "react";

const questionInit = {
  question: "",
  correct: [],
  type: "fillInBlank",
  answers: [],
  refPage: "",
};

export default function FillInBlank({ setQuestionData, questionData }) {

  useEffect(() => {
    if (!questionData?.question) {
      setQuestionData(questionInit);
    } else {
      return;
    }
  }, [questionData?.question]);

  const handleSelectAnswer = (word) => {
    setQuestionData({ ...questionData, answers: [...questionData.answers, word], correct: [...questionData.answers, word]});   
  };

  const handleRemoveAnswer = (index) => {
    const newAnswers = [...questionData.answers];  
    newAnswers.splice(index, 1);
    setQuestionData({ ...questionData, answers: newAnswers, correct: newAnswers});
  };

  useEffect(() => {
    setQuestionData({ ...questionData, type: "fillInBlank", correct: [], answers: []});
  }, []);


  return (
    <div className="mt-4 space-y-6 pb-36 max-w-4xl mx-auto">
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Question
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="question"
            id="question"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={questionData?.question || ""}
            onChange={(e) =>
              setQuestionData({ ...questionData, question: e.target.value })
            }
          />
        </div>
      </div>

      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Select the words that can fit the blank.
        </label>
        <div className="flex items-center gap-1 flex-wrap">
          {questionData?.question &&
            questionData?.question.split(" ").map((word, i) => {
              // Remove commas, periods, and quotations from the word
              const wordWithoutPunctuation = word.replace(/[.,"]/g, "");
              return (
                <button
                  className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20 ${
                    questionData?.answers?.includes(wordWithoutPunctuation)
                      ? "bg-green-50"
                      : "bg-white text-zinc-950 "
                  }`}
                  key={i}
                  onClick={() => handleSelectAnswer(wordWithoutPunctuation)}
                  disabled={questionData?.answers?.includes(wordWithoutPunctuation)}
                >
                  {wordWithoutPunctuation}
                </button>
              );
            })}
        </div>
      </div>

      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Selected words, click to remove.
        </label>
        <div className="flex items-center gap-1">
          {questionData?.answers?.map((word, i) => (
            <button
              className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
              key={i}
              onClick={() => handleRemoveAnswer(i)}
            >
              {word}
            </button>
          ))}
        </div>
      </div>
      <div>
        <div className="mt-4 text-3xl flex items-center gap-x-4">
        <input
            type="text"
            name="linkPage"
            id="linkPage"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={questionData?.refPage}
            placeholder="Ref Page or URL"
            onChange={(e) => setQuestionData({ ...questionData, refPage: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
}
