import { PlusCircle } from "lucide-react";
import { useEffect, useState } from "react";


export default function Order({ setQuestionData, questionData}) {
  const handleAddAnswer = () => {
    setQuestionData({ ...questionData, answers: [...questionData.answers, ""] });
  };

  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...questionData.answers];
    updatedAnswers[index] = value;
    setQuestionData({
      ...questionData,
      answers: updatedAnswers,
      correct: updatedAnswers,
    });
  };

  const handleRemoveAnswer = (index) => {
    const updatedAnswers = [...questionData.answers];
    const updatedCorrect = [...questionData.correct];

    // Remove the answer and the corresponding correct answer from their arrays
    updatedAnswers.splice(index, 1);
    updatedCorrect.splice(index, 1);

    // Update the state with the modified arrays
    setQuestionData({
      ...questionData,
      answers: updatedAnswers,
      correct: updatedCorrect,
    });
  };
  useEffect(() => {
    setQuestionData({ ...questionData, type: "order", answers: [""], correct: [""]});
  }, []);


  return (
    <div className="mt-4 space-y-6 pb-36 max-w-4xl mx-auto">
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Intructions or Question{" "}
          <span className="font-normal text-xs">
            (leave blank if not needed)
          </span>
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="question"
            id="question"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={questionData?.question}
            onChange={(e) =>
              setQuestionData({ ...questionData, question: e.target.value })
            }
          />
        </div>
      </div>
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Order the following items
        </label>
        <fieldset>
          <div className="space-y-5 mt-4">
            {questionData?.answers.map((answer, index) => (
              <div className="flex items-start gap-x-3">
                <div className="flex items-center">{index + 1}.</div>
                <div className="w-full col-span-5" key={index}>
                  <textarea
                    id={`check-${index}`}
                    aria-describedby={`check-${index}-description`}
                    rows={2}
                    name={`check-${index}`}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    value={questionData.answers[index]}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                  />
                </div>
                <div className="ml-auto">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md  text-red-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                    onClick={() => handleRemoveAnswer(index)}
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm-3-8a1 1 0 011-1h4a1 1 0 010 2H8a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
            <button
              className="mt-4 text-green-600 py-2 px-4 rounded hover:text-green-700  flex items-center gap-x-2"
              onClick={handleAddAnswer}
            >
              <PlusCircle size={24} />
              Add Line
            </button>
          </div>
        </fieldset>
      </div>
      <div>
        <div className="mt-4 text-3xl flex items-center gap-x-4">
          <input
            type="text"
            name="linkPage"
            id="linkPage"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            placeholder="Ref Page or URL"
            value={questionData?.refPage}
            onChange={(e) =>
              setQuestionData({ ...questionData, refPage: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}
