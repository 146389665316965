import { useState } from "react";
import { Link } from "react-router-dom";
import { forgotPassword } from "../functions/auth";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    console.log("Login");
    forgotPassword(email).then((res) => {
      if (res) {
        console.log("Email sent");
        setEmailSent(true);
      }
    });
  };

  const handleEmail = (e) => {
    console.log(e.target.value);
    setEmail(e.target.value);
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12  bg-zinc-950/80 h-screen">
        {emailSent ? (
          <div className="flex flex-col items-center justify-center">
            <h2 className="text-white text-2xl font-bold leading-9">
              Email sent
            </h2>
            <p className="text-white text-sm mt-2">
              Please check your email to reset your password
            </p>
          </div>
        ) : (
          <>
            <div className="">
              <h2 className="mt-8 text-center text-2xl font-bold leading-9 text-white font-frank tracking-wider ">
                Reset your password
              </h2>
            </div>

            <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-green-500 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => handleEmail(e)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 h-12"
                    onClick={(e) => handleLogin(e)}
                  >
                    Send reset link
                  </button>
                </div>
              </form>

              <p className="mt-10 text-center text-sm text-gray-400">
                Not a member?{" "}
                <Link
                  to="/create-account"
                  className="ml-1 font-semibold leading-6 text-green-400 hover:text-green-300"
                >
                  Create your account
                </Link>
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
}
