import { Edit } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/fire";

const projectVals = [
  "coreValues",
  "fitness",
  "firstaid",
  "leadership",
  "teamwork",
  "landnav",
  "weapons",
  "drill",
  "customs",
  "combatTraining",
  "les",
  "tacticalTraining",
  "creed",
  "history",
  "ethos",
  "uniform",
  "militaryJustice",
  "generalOrders",
  "fieldTraining",
  "advancedWeapons",
];

const projects = [
  {
    name: "Core Values",
    initials: "CV",
    href: "/coreValues",
    numberOfQuestions: 0,
    bgColor: "bg-yellow-500",
    id: "coreValues",
  },
  {
    name: "Physical Training (PT)",
    initials: "PT",
    href: "/fitness",
    numberOfQuestions: 0,
    bgColor: "bg-green-600",
    id: "fitness",
  },
  {
    name: "First Aid",
    initials: "FA",
    href: "/firstaid",
    numberOfQuestions: 0,
    bgColor: "bg-red-600",
    id: "firstaid",
  },
  {
    name: "Leadership",
    initials: "L",
    href: "/leadership",
    numberOfQuestions: 0,
    bgColor: "bg-blue-600",
    id: "leadership",
  },
  {
    name: "Teamwork",
    initials: "TW",
    href: "/teamwork",
    numberOfQuestions: 0,
    bgColor: "bg-yellow-500",
    id: "teamwork",
  },
  {
    name: "Land Navigation",
    initials: "LN",
    href: "/landnav",
    numberOfQuestions: 0,
    bgColor: "bg-green-600",
    id: "landnav",
  },
  {
    name: "Weapons",
    initials: "W",
    href: "/weapons",
    numberOfQuestions: 0,
    bgColor: "bg-red-600",
    id: "weapons",
  },
  {
    name: "Drill and Ceremony",
    initials: "DC",
    href: "/drill",
    numberOfQuestions: 0,
    bgColor: "bg-blue-600",
    id: "drill",
  },
  {
    name: "Customs and Courtesies",
    initials: "CC",
    href: "/customs",
    numberOfQuestions: 0,
    bgColor: "bg-yellow-500",
    id: "customs",
  },
  {
    name: "Combat Training",
    initials: "CT",
    href: "/combatTraining",
    numberOfQuestions: 0,
    bgColor: "bg-green-600",
    id: "combatTraining",
  },
  {
    name: "Leave and Earnings Statement (LES)",
    initials: "LES",
    href: "/les",
    numberOfQuestions: 0,
    bgColor: "bg-red-600",
    id: "les",
  },
  {
    name: "Tactical Training",
    initials: "TT",
    href: "/tacticalTraining",
    numberOfQuestions: 0,
    bgColor: "bg-blue-600",
    id: "tacticalTraining",
  },
  {
    name: "Soldier's Creed",
    initials: "SC",
    href: "/creed",
    numberOfQuestions: 0,
    bgColor: "bg-yellow-500",
    id: "creed",
  },
  {
    name: "Army History",
    initials: "AH",
    href: "/history",
    numberOfQuestions: 0,
    bgColor: "bg-green-600",
    id: "history",
  },
  {
    name: "Ethos",
    initials: "E",
    href: "/ethos",
    numberOfQuestions: 0,
    bgColor: "bg-red-600",
    id: "ethos",
  },
  {
    name: "Uniform and Appearance",
    initials: "UA",
    href: "/uniform",
    numberOfQuestions: 0,
    bgColor: "bg-blue-600",
    id: "uniform",
  },
  {
    name: "Military Justice",
    initials: "MJ",
    href: "/militaryJustice",
    numberOfQuestions: 0,
    bgColor: "bg-yellow-500",
    id: "militaryJustice",
  },
  {
    name: "General Orders",
    initials: "GO",
    href: "/generalOrders",
    numberOfQuestions: 0,
    bgColor: "bg-green-600",
    id: "generalOrders",
  },
  {
    name: "Field Training",
    initials: "FT",
    href: "/fieldTraining",
    numberOfQuestions: 0,
    bgColor: "bg-red-600",
    id: "fieldTraining",
  },
  {
    name: "Advanced Weapons",
    initials: "AW",
    href: "/advancedWeapons",
    numberOfQuestions: 0,
    bgColor: "bg-blue-600",
    id: "advancedWeapons",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const { setCourseToEdit } = UserAuth();
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [dataForTypes, setDataForTypes] = useState([]);

  const getAllDataTypes = async () => {
    for (let i = 0; i < projectVals.length; i++) {
      const docRef = doc(db, "courseStats", projectVals[i]);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const newData = { ...docSnap.data(), id: projectVals[i] };
        setDataForTypes((prev) => [...prev, newData]);
        console.log("Document ran");
      }
    }
  };

  useEffect(() => {
    getAllDataTypes();
  }, []);

  const handleEdit = (course) => {
    setCourseToEdit(course);
    navigate(`/view`);
  };

  useEffect(() => {
    const newCourses = projects.map((project) => {
      const data = dataForTypes.find((item) => item.id === project.id);
      if (data) {
        return { ...project, numberOfQuestions: data.numberOfQuestions };
      }
      return project;
    });
    setCourses(newCourses);
  }, [dataForTypes]);

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="pt-24">
        <h1 className="text-3xl font-bold">Welcome to ReadyFrontCover Admin</h1>
        <p className="text-gray-500">Select a course to get started</p>
      </div>

      <h2 className="text-sm font-medium text-gray-500">All Courses</h2>
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        {courses.map((project) => (
          <li
            key={project.name}
            className="col-span-1 flex rounded-md shadow-sm"
          >
            <div
              className={classNames(
                project.bgColor,
                "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
              )}
            >
              {project.initials}
            </div>
            <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
              <div className="flex-1 truncate px-4 py-2 text-sm">
                <a
                  href={project.href}
                  className="font-medium text-gray-900 hover:text-gray-600"
                >
                  {project.name}
                </a>
                <p className="text-gray-500">
                  {project.numberOfQuestions} Questions
                </p>
              </div>
              <div className="flex-shrink-0 pr-2">
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  onClick={() => handleEdit(project)}
                >
                  <span className="sr-only">Open options</span>
                  <Edit className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
