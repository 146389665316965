import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase/fire";
import { addQuestion } from "./firebaseFunctions";

export const uploadImage = async (file, courseID, questionDATA) => {
  const generateRandomID = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomID = "";

    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomID += characters.charAt(randomIndex);
    }

    return randomID;
  };
  const newID = generateRandomID();
  const storageRef = ref(storage, `images/${courseID}/${newID}`);
  try {
    const snapshot = await uploadBytes(storageRef, file);
    console.log("Uploaded a blob or file!");
    const url = await getDownloadURL(snapshot.ref);
    console.log("File available at", url);
    questionDATA.image = url;
    await addQuestion(questionDATA, courseID);
    return { success: true, message: "Document successfully written!" };
  } catch (error) {
    console.error("Error uploading image", error);
    return { success: false, message: "Error uploading image" };
  }
};
