import { ref, onValue } from "firebase/database";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { database } from "../firebase/fire";
import { UserAuth } from "../context/AuthContext";

const types = {
  multipleChoice: "Multiple Choice",
  trueFalse: "True/False",
  fillInBlank: "Fill in the Blank",
  matching: "Matching",
  order: "Order",
  memorize: "Memorize",
  selectAllThatApply: "Select All That Apply",
};

const answers = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
]

export default function AllQuestions() {
  const { courseToEdit } = UserAuth();
  const [questions, setQuestions] = useState([]);

  const getQuestions = async (courseID) => {
    const questionRef = ref(database, "questions/" + courseID);
    onValue(
      questionRef,
      (snapshot) => {
        const dataList = [];
        snapshot.forEach((childSnapshot) => {
          const childData = childSnapshot.val();
          console.log(childData);
          const id = childSnapshot.key;
          console.log(id);
          childData.id2 = id;
          dataList.push(childData);
        });
        setQuestions(dataList);
      },
      {
        onlyOnce: true,
      }
    );
  };

  useEffect(() => {
    const theID = courseToEdit?.id;
    getQuestions(theID);
  }, []);

  console.log(questions);

  const { course } = useParams();
  return (
    <div>
      <h1>All Questions for {course}</h1>

      <div className="mt-8 flow-root">
        <div className="overflow-x-auto ">
          <div className="inline-block min-w-full align-middle ">
            <table className="min-w-full divide-y divide-gray-300s">
              <thead className="">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 w-96"
                  >
                    Question
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Answers
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Correct
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Role
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white ">
                {questions?.map((person) => (
                  <tr key={person.email} className="w-96">
                    <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm text-wrap ">
                      <div className="flex items-center">
                        {person.image && (
                          <div className="h-11 w-11 flex-shrink-0">
                            <img
                              className="h-11 w-11 rounded-sm object-cover object-center"
                              src={person.image}
                              alt=""
                            />
                          </div>
                        )}

                        <div className="ml-4">
                          <div className="font-medium text-gray-900 line-clamp-3">
                            {person.question}
                          </div>
                          <div className="mt-1 text-gray-500 overflow-hidden">
                            {person.refPage.length > 15
                              ? `${person.refPage.slice(0, 15)}...`
                              : person.refPage}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-wrap ">
                      {person.answers.map((answer, index) => (
                        <div key={index} className="text-gray-900 line-clamp-1">
                          {answer}
                        </div>
                      ))}
                      <div className="mt-1 text-gray-500">
                        {person.department}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 text-wrap ">
                      {typeof(person.correct) !== typeof(0) ? person.correct.map((answer, index) => (
                        <div key={index} className="text-gray-900 line-clamp-1">
                          {answer}
                        </div>
                      )) : person.type === "memorize" ? person?.answers?.length + " lines" : answers[person.correct]}
                      <div className="mt-1 text-gray-500">
                        {person.department}
                      </div>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                      {types[person.type]}
                      </span>
                    </td>
                    <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                      {person.role}
                    </td>
                    <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium ">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit<span className="sr-only">, {person.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

  
    </div>
  );
}
