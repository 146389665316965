import React from 'react'
import AddQuestionTabs from '../components/AddQuestionTabs'

export default function AddQuestions() {
  return (
    <div>
        <AddQuestionTabs />
    </div>
  )
}
