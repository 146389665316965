import { Image, MinusCircle } from "lucide-react";
import { useEffect } from "react";

export default function TrueFalse({ setQuestionData, questionData, display, setDisplay }) {

  const handleUploadProfilePicture = (e) => {
    const file = e.target.files[0];
    const fileURL = URL.createObjectURL(file);
    setQuestionData({ ...questionData, file: file, hasImage: true });
    setDisplay(fileURL);
  };

  const removeImage = () => {
    setQuestionData({ ...questionData, file: null, hasImage: false });
    setDisplay(false);
  };

  useEffect(() => {
    setQuestionData({ ...questionData, type: "trueFalse", answers: ["True", "False"], correct: 0 });
  }, []);

  return (
    <div className="mt-4 space-y-6 pb-36 max-w-4xl mx-auto">
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Question
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="question"
            id="question"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={questionData?.question || ""} 
            onChange={(e) =>
              setQuestionData({ ...questionData, question: e.target.value })
            }
          />
        </div>
        <div className="mt-2">
          {questionData?.hasImage && (
            <img src={display} alt="display" className="w-auto h-auto" />
          )}
        </div>
        <div className="flex items-center mt-4 gap-4">
          <div>
            <input
              type="file"
              id="imageForQuestion"
              name="imageForQuestion"
              className="sr-only"
              onChange={handleUploadProfilePicture}
            />
            <label
              htmlFor="imageForQuestion"
              className="text-zinc-700 flex items-center  text-sm gap-x-1 cursor-pointer"
            >
              <Image className="" size={24} />
              <div>
                {display ? "Change " : "Add "}
                Image
              </div>
            </label>
          </div>
          <div>
            {display && (
              <button
                type="button"
                className="text-sm flex items-center text-red-500 gap-x-1 hover:text-red-700"
                onClick={removeImage}
              >
                <MinusCircle size={14} />
                Remove Image
              </button>
            )}
          </div>
        </div>
      </div>
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Answers
        </label>
        <fieldset>
          <div className="space-y-5 mt-4">
            <div className="flex items-start gap-x-3">
              <div className="flex items-center">
                <input
                  id="a-check"
                  aria-describedby="a-check-description"
                  name="a-check"
                  type="checkbox"
                  checked={questionData?.correct === 0}
                  className="h-10 w-10 rounded border-gray-300 text-green-600 focus:ring-green-600 cursor-pointer"
                  onClick={() => setQuestionData({ ...questionData, correct: 0 })}
                />
              </div>
              <div className="w-full">True</div>
            </div>
            <div className="flex items-start gap-x-3">
              <div className="flex items-center">
                <input
                  id="b-check"
                  aria-describedby="b-check-description"
                  name="b-check"
                  type="checkbox"
                  checked={questionData?.correct === 1}
                  className="h-10 w-10 rounded border-gray-300 text-green-600 focus:ring-green-600 cursor-pointer"
                  onClick={() => setQuestionData({ ...questionData, correct: 1 })}
                />
              </div>
              <div className="w-full">False</div>
            </div>
          </div>
        </fieldset>
      </div>
      <div>
        <div className="mt-4 text-3xl flex items-center gap-x-4">
          <input
            type="text"
            name="linkPage"
            id="linkPage"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            placeholder="Ref Page or URL"
            value={questionData?.refPage}
            onChange={(e) =>
              setQuestionData({ ...questionData, refPage: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}
