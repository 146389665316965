import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { UserAuth } from "./context/AuthContext";
import { logout } from "./functions/auth";
import Login from "./pages/Login";
import CreateAccount from "./pages/CreateAccount";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Dashboard from "./components/Dashboard";
import AllQuestions from "./pages/AllQuestions";
import AddQuestions from "./pages/AddQuestions";
import { useEffect } from "react";

function App() {
  const { isLoggedIn, courseToEdit } = UserAuth();
  const path = useLocation().pathname;
  
  const handleLogout = () => {
    logout();
  };
  
  return (
    <div>
      {isLoggedIn ? (
        <>
          {path === "/" ? (
            <Home />
          ) : (
            <Dashboard>
              <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="/view" element={<AllQuestions />} />
                <Route path="/add" element={<AddQuestions />} />
                <Route
                  path="/logout"
                  element={
                    <div>
                      <button
                        onClick={() => {
                          handleLogout();
                        }}
                      >
                        Logout
                      </button>
                    </div>
                  }
                />
                <Route path="/*" element={<Home />} />
              </Routes>
            </Dashboard>
          )}
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
