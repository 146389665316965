//firebase realtime database crud operations

import { set, ref } from "firebase/database";
import { database, db } from "../firebase/fire";
import { doc, increment, setDoc, updateDoc } from "firebase/firestore";

export const addQuestion = async (question, courseID) => {
  const generateRandomID = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomID = "";

    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomID += characters.charAt(randomIndex);
    }

    return randomID;
  };

  const newID = generateRandomID();

  try {
    const questionRef = ref(database, `questions/${courseID}/${newID}`);

    await set(questionRef, question);
    const docRef = doc(db, "courseStats", courseID);
    await updateDoc(docRef, {
      numberOfQuestions: increment(1),
      [question.type]: increment(1),
    });
    return { success: true, message: "Document successfully written!" };
  } catch (error) {
    console.error("Error adding document: ", error);
    return { success: false, message: "Error adding document" };
  }
};
