import { Fragment, useEffect, useState } from "react";
import AddQuestion from "./AddQuestion";
import { addQuestion } from "../functions/firebaseFunctions";
import { UserAuth } from "../context/AuthContext";
import { uploadImage } from "../functions/storageFunctions";
import { CheckCircle, X } from "lucide-react";
import { Transition } from "@headlessui/react";

const tabsInit = [
  { name: "Multiple Choice", href: "#", current: true, id: 0 },
  { name: "True or False", href: "#", current: false, id: 1 },
  { name: "Fill in the Blank", href: "#", current: false, id: 2 },
  { name: "Matching", href: "#", current: false, id: 3 },
  { name: "Order", href: "#", current: false, id: 4 },
  { name: "Memorize", href: "#", current: false, id: 5 },
  { name: "Select Multiple", href: "#", current: false, id: 6 },
];

const questionInit = {
  question: "",
  correct: 0,
  type: "multipleChoice",
  answers: [""],
  refPage: "",
  hasImage: false,
  file: null,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AddQuestionTabs() {
  const { courseToEdit } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(tabsInit);
  const [questionData, setQuestionData] = useState(questionInit);
  const [display, setDisplay] = useState(false);
  const [show, setShow] = useState(false);

  const uploadQuestion = (questionData) => {
    if (questionData?.hasImage) {
      uploadImage(questionData.file, courseToEdit?.id, questionData)
        .then((res) => {
          console.log(res, "res");
          if (res.success) {
            setQuestionData(questionInit);
            setDisplay(false);
            setLoading(false);
            setShow(true);
          }
        })
        .catch((error) => {
          console.error("Error uploading image", error);
          setLoading(false);
        });
    } else {
      addQuestion(questionData, courseToEdit?.id)
        .then((res) => {
          console.log(res, "res");
          if (res.success) {
            setQuestionData(questionInit);
            setDisplay(false);
            setLoading(false);
            setShow(true);
          }
        })
        .catch((error) => {
          console.error("Error adding question", error);
          setLoading(false);
        });
    }
  };

  const handleAddQuestion = () => {
    setLoading(true);
    if (questionData.type === "multipleChoice") {
      if (questionData.answers.length < 2) {
        alert("You need at least two answers");
        setLoading(false);
        return;
      } else if (questionData.answers.includes("")) {
        alert("You need to fill out all answers");
        setLoading(false);
        return;
      } else if (questionData.correct === null) {
        alert("You need to select a correct answer");
        setLoading(false);
        return;
      } else if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else if (questionData.type === "trueFalse") {
      if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else if (questionData.type === "fillInBlank") {
      if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else if (questionData.answers.length === 0) {
        alert("You need to add at least one answer");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else if (questionData.type === "matching") {
      if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else if (questionData.answers.includes("")) {
        alert("You need to fill out all answers");
        setLoading(false);
        return;
      } else if (questionData.correct.includes("")) {
        alert("You need to fill out all correct answers");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else if (questionData.type === "order") {
      if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else if (questionData.answers.includes("")) {
        alert("You need to fill out all answers");
        setLoading(false);
        return;
      } else if (questionData.correct.includes("")) {
        alert("You need to fill out all correct answers");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else if (questionData.type === "memorize") {
      if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else if (questionData.answers.includes("")) {
        alert("You need to fill out all answers");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else if (questionData.type === "selectAllThatApply") {
      if (questionData.question === "") {
        alert("You need to write a question");
        setLoading(false);
        return;
      } else if (questionData.answers.length < 2) {
        alert("You need at least two answers");
        setLoading(false);
        return;
      } else if (questionData.answers.includes("")) {
        alert("You need to fill out all answers");
        setLoading(false);
        return;
      } else if (questionData.correct.length === 0) {
        alert("You need to select at least one correct answer");
        setLoading(false);
        return;
      } else {
        uploadQuestion(questionData);
      }
    } else {
      console.error("Invalid question type");
    }
  };

  const handleClearAll = () => {
    if (questionData?.type === "matching") {
      setQuestionData({
        ...questionData,
        answers: [""],
        correct: [""],
        question: "",
      });
    } else if (questionData?.type === "order") {
      setQuestionData({
        ...questionData,
        answers: [""],
        correct: [""],
        question: "",
      });
    } else if (questionData?.type === "trueFalse") {
      setQuestionData({
        ...questionData,
        answers: ["True", "False"],
        correct: 0,
        question: "",
      });
    } else if (questionData?.type === "memorize") {
      setQuestionData({
        ...questionData,
        answers: [""],
        correct: 0,
        question: "",
      });
    } else if (questionData?.type === "selectAllThatApply") {
      setQuestionData({
        ...questionData,
        answers: [""],
        correct: [0],
        question: "",
      });
    } else if (questionData?.type === "fillInBlank") {
      setQuestionData({
        ...questionData,
        answers: [],
        correct: [],
        question: "",
      });
    } else {
      setQuestionData(questionInit);
    }
    setDisplay(false);
  };

  useEffect(() => {
    handleClearAll();
  }, [questionData?.type]);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  }, [show]);

  console.log(questionData, "questionData");

  return (
    <>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 ">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircle
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully saved!
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Anyone with a link can now view this file.
                    </p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <X className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      {loading ? (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-md">
            <p className="text-center">Saving...</p>
          </div>
        </div>
      ) : (
        <>
          <div className="border-b border-gray-200  sticky top-16 p-4 bg-white rounded-md">
            <div className="md:flex md:items-center md:justify-between">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Question Types
              </h3>
              <div>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={handleClearAll}
                >
                  Clear All
                </button>
                <button
                  type="button"
                  className="ml-3 inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 "
                  onClick={handleAddQuestion}
                >
                  Add Question
                </button>
              </div>
            </div>
            <div className="mt-4 sticky top-10">
              <div className="">
                <nav className="-mb-px flex space-x-4">
                  {tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={classNames(
                        tab.current
                          ? "border-green-500 text-green-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "whitespace-nowrap border-b-2 px-1 pb-4 text-xs font-medium"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                      onClick={() => {
                        setTabs(
                          tabs.map((t) => ({
                            ...t,
                            current: t.name === tab.name,
                          }))
                        );
                      }}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <AddQuestion
            questionType={tabs.find((tab) => tab.current).id}
            setQuestionData={setQuestionData}
            questionData={questionData}
            display={display}
            setDisplay={setDisplay}
          />
          <div className="flex">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 ml-auto"
              onClick={handleAddQuestion}
            >
              Add Question
            </button>
          </div>
        </>
      )}
    </>
  );
}
