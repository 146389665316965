import { Image, MinusCircle, PlusCircle } from "lucide-react";
import { useEffect, useState } from "react";

export default function SelectAllThatApply({
  setQuestionData,
  questionData,
  display,
  setDisplay,
}) {
  const handleUploadProfilePicture = (e) => {
    const file = e.target.files[0];
    const fileURL = URL.createObjectURL(file);
    setQuestionData({ ...questionData, file: file, hasImage: true });
    setDisplay(fileURL);
  };

  const removeImage = () => {
    setQuestionData({ ...questionData, file: null, hasImage: false });
    setDisplay(false);
  };

  const handleAddAnswer = () => {
    setQuestionData({
      ...questionData,
      answers: [...questionData.answers, ""],
    });
  };

  const handleAnswerChange = (index, value, isChecked) => {
    const updatedAnswers = [...questionData.answers];
    updatedAnswers[index] = value;

    let updatedCorrect = [...questionData.correct]; // Copy the correct array

    if (isChecked) {
      updatedCorrect.push(index); // Add the index to correct array if checked
    } else {
      updatedCorrect = updatedCorrect.filter((item) => item !== index); // Remove the index if unchecked
    }

    setQuestionData({
      ...questionData,
      answers: updatedAnswers,
      correct: updatedCorrect,
    });
  };

  const handleRemoveAnswer = (index) => {
    const updatedAnswers = questionData.answers.filter(
      (_, answerIndex) => answerIndex !== index
    );
    setQuestionData({
      ...questionData,
      answers: updatedAnswers,
      correct: [],
    });
  };

  useEffect(() => {
    setQuestionData({
      ...questionData,
      type: "selectAllThatApply",
      answers: [""],
      correct: [0],
    });
  }, []);

  return (
    <div className="mt-4 space-y-6 pb-36 max-w-4xl mx-auto">
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Question
        </label>
        <div className="mt-2">
          <textarea
            rows={4}
            name="question"
            id="question"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            value={questionData?.question || ""}
            onChange={(e) =>
              setQuestionData({ ...questionData, question: e.target.value })
            }
          />
        </div>
      </div>
      <div className="mt-2">
        {questionData?.hasImage && (
          <img src={display} alt="display" className="w-auto h-auto" />
        )}
      </div>
      <div className="flex items-center mt-4 gap-4">
        <div>
          <input
            type="file"
            id="imageForQuestion"
            name="imageForQuestion"
            className="sr-only"
            onChange={handleUploadProfilePicture}
          />
          <label
            htmlFor="imageForQuestion"
            className="text-zinc-700 flex items-center  text-sm gap-x-1 cursor-pointer"
          >
            <Image className="" size={24} />
            <div>
              {display ? "Change " : "Add "}
              Image
            </div>
          </label>
        </div>
        <div>
          {display && (
            <button
              type="button"
              className="text-sm flex items-center text-red-500 gap-x-1 hover:text-red-700"
              onClick={removeImage}
            >
              <MinusCircle size={14} />
              Remove Image
            </button>
          )}
        </div>
      </div>
      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Answers <span className="text-gray-500">(Select all that apply)</span>
        </label>
        <fieldset>
          <div className="space-y-5 mt-4">
            {questionData?.answers?.map((answer, index) => (
              <div className="flex items-start gap-x-3" key={index}>
                <div className="flex items-center">
                  <input
                    id={`check-${index}`}
                    aria-describedby={`check-${index}-description`}
                    name={`check-${index}`}
                    type="checkbox"
                    checked={questionData?.correct?.length > 0 && questionData?.correct?.includes(index)} // Check if index is in correct array
                    className="h-10 w-10 rounded border-gray-300 text-green-600 focus:ring-green-600 cursor-pointer"
                    onChange={(e) =>
                      handleAnswerChange(
                        index,
                        questionData.answers[index],
                        e.target.checked
                      )
                    }
                  />
                </div>
                <div className="w-full">
                  <textarea
                    rows={2}
                    name={`answer-${index}`}
                    id={`answer-${index}`}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    value={answer}
                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                  />
                </div>
                <div className="ml-auto">
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md  text-red-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2"
                    onClick={() => handleRemoveAnswer(index)}
                  >
                    <svg
                      className="h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm-3-8a1 1 0 011-1h4a1 1 0 010 2H8a1 1 0 01-1-1z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </fieldset>
        <button
          className="mt-4 text-green-600 py-2 px-4 rounded hover:text-green-700  flex items-center gap-x-2"
          onClick={handleAddAnswer}
        >
          <PlusCircle size={24} />
          Add Answer
        </button>
      </div>
      <div>
        <div className="mt-4 text-3xl flex items-center gap-x-4">
          <input
            type="text"
            name="linkPage"
            id="linkPage"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            placeholder="Ref Page or URL"
            value={questionData?.refPage}
            onChange={(e) =>
              setQuestionData({ ...questionData, refPage: e.target.value })
            }
          />
        </div>
      </div>
    </div>
  );
}
